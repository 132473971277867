<template>
  <div>


</div>

</template>

<script>

import ProjectService from '../services/ProjectService.js';
const projectService = new ProjectService;


export default {
  name: 'Project',
  components: {},
  
created() {
    let id = this.$route.params.id;

    projectService.getProjectData(id)
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      console.log(err);
    });
}

}




</script>

<style>


</style>
