<template>

  <div class = "container">

      <div class="row mr-0 ml-0 pr-0 pl-0 mt-5 mt-lg-0">
          <div class = "col-lg-6 col-md-6 mt-5 mt-xxl-18 mt-xl-14 mt-lg-10 proxima order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">Projects</h1>
              <p class="custom-body mt-4">
                Our team has been fortunate to have the opportunity to work with a variety of clients on a number of different projects. 
                We take pride in creating custom solutions and designs that keep users engaged and create longstanding relationships.
                <br><br> 
                Here you can view a small selection of some of our recent projects to give you a better idea of what we can do.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 col-md-6 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2 pt-6">
            <img class="width-full-auto-height" src="../assets/images/projects/Projects.png" alt="Project Page Banner"/>
          </div>
      </div>

    <div class ="row mt-10 text-center">
      <div style="width: 100%;">
        <h1 class="aller-bd mt-4 mb-4">Our Work</h1>


<div>
  <b-dropdown
    block
    v-model="selected"
    variant="primary"
    :text="this.selected"
    class="m-2 show-mobile px-4"
  >
    <b-dropdown-item @click="showAll()">All</b-dropdown-item>
    <b-dropdown-item @click="showWebDesign()">Website Design</b-dropdown-item>
    <b-dropdown-item @click="showWebApp()">Website Application</b-dropdown-item>
    <b-dropdown-item @click="showPlugin()">Custom Plugin</b-dropdown-item>
    <b-dropdown-item @click="showMarketing()">Marketing Campaign</b-dropdown-item>
    <b-dropdown-item @click="showBranding()">Branding</b-dropdown-item>
  </b-dropdown>
</div>





        <b-card class="text-center border-top-0 border-bottom-0 border-left-0 border-right-0">
          <b-tabs pills card fill align="center" class="border-bottom-0">

      <!-- All Projects Tab -->
                  <b-tab title="All" active ref="all">
                    <div class ="row mt-5">
                      <template v-for="project in projects">
                            <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                              <div class ="project-description-wrapper">
                                  <div class="project-image-wrapper shadow rounded">
                                    <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                  </div>
                                  <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                  <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                    <p class="custom-body proxima">
                                      {{project.description}}
                                    </p>
                              </div>
                            </div>
                      </template>
                    </div>
                  </b-tab>
      <!-- Web Design Tab -->
                  <b-tab title="Website Design" ref="webDesign">
                    <div class ="row mt-5">
                      <template v-for="project in webDesign">
                            <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                              <div class ="project-description-wrapper">
                                  <div class="project-image-wrapper shadow rounded">
                                    <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                  </div>
                                  <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                  <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                    <p class="custom-body proxima">
                                      {{project.description}}
                                    </p>
                              </div>
                            </div>
                      </template>
                    </div>

                  </b-tab>
      <!-- Web App Tab -->
                  <b-tab title="Website Application" ref="webApp">
                    <div class ="row mt-5">
                      <template v-for="project in webApp">
                            <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                              <div class ="project-description-wrapper">
                                  <div class="project-image-wrapper shadow rounded">
                                    <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                  </div>
                                  <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                  <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                    <p class="custom-body proxima">
                                      {{project.description}}
                                    </p>
                              </div>
                            </div>
                      </template>
                    </div>
                  </b-tab>
      <!-- Plugin Tab -->
                <b-tab title="Custom Plugin" ref="plugin">
                    <div class ="row mt-5">
                      <template v-for="project in customPlugin">
                            <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                              <div class ="project-description-wrapper">
                                  <div class="project-image-wrapper shadow rounded">
                                    <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                  </div>
                                  <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                  <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                    <p class="custom-body proxima">
                                      {{project.description}}
                                    </p>
                              </div>
                            </div>
                      </template>
                    </div>
                  </b-tab>
      <!-- Marketing Tab -->
                <b-tab title="Marketing Campaign" ref="marketing">
                    <div class ="row mt-5">
                      <template v-for="project in marketing">
                            <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                              <div class ="project-description-wrapper">
                                  <div class="project-image-wrapper shadow rounded">
                                    <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                  </div>
                                  <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                  <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                    <p class="custom-body proxima">
                                      {{project.description}}
                                    </p>
                              </div>
                            </div>
                      </template>
                    </div>
                  </b-tab>
        <!-- Branding Tab -->
                  <b-tab title="Branding" ref="branding">
                      <div class ="row mt-5">
                        <template v-for="project in branding">
                              <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                                <div class ="project-description-wrapper">
                                    <div class="project-image-wrapper shadow rounded">
                                      <img v-if="project.image_thumbnail" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                                    </div>
                                    <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                                    <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                                      <p class="custom-body proxima">
                                        {{project.description}}
                                      </p>
                                </div>
                              </div>
                        </template>
                      </div>
                    </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

  <b-modal scrollable modal-class="modal-fullscreen" id="project-image-modal" title="Project Details">
      <img v-if="selectedModalImage" :src="require(`../assets/${this.selectedModalImage}`)" style="width: 100%"/>
  </b-modal>

  </div>
</template>

<script>

import ProjectService from '../services/ProjectService.js';
const projectService = new ProjectService;


export default {
  name: 'Projects',

  data() {
      return {
          projects: [],
          webDesign: [],
          webApp: [],
          customPlugin: [],
          marketing: [],
          branding: [],
          selectedModalImage: "",
          selected: "All",
        };
  },

  components: {},

  methods: {
    viewModalImage(image_url) {
      this.selectedModalImage = image_url;
      this.$bvModal.show('project-image-modal');
    },
    showAll(){
      this.$refs.all.activate();
      this.selected = "All";
    },
    showWebDesign() {
      this.$refs.webDesign.activate();
      this.selected = "Website Design";
    },
    showWebApp() {
      this.$refs.webApp.activate();
      this.selected = "Website Application";
    },
    showPlugin() {
      this.$refs.plugin.activate();
      this.selected = "Custom Plugin";
    },
    showMarketing() {
      this.$refs.marketing.activate();
      this.selected = "Marketing Campaign";
    },
    showBranding(){
      this.$refs.branding.activate();
      this.selected = "Branding";
    },
  },

  mounted() {
    projectService.getProjects()
    .then(res => {
      this.projects = res.data;
      //console.log(this.projects);

      for (let i = 0; i<this.projects.length; i++){
        for (let j =0; j<this.projects[i].categories.length; j++){
          if (this.projects[i].categories[j].Label === "Website Design"){
            this.webDesign.push(this.projects[i]);
          } else if (this.projects[i].categories[j].Label === "Web Application"){
            this.webApp.push(this.projects[i]);
          } else if (this.projects[i].categories[j].Label === "Custom Plugin"){
            this.customPlugin.push(this.projects[i]);
          } else if (this.projects[i].categories[j].Label === "Marketing Campaign"){
            this.marketing.push(this.projects[i]);
          } else if (this.projects[i].categories[j].Label === "Branding"){
            this.branding.push(this.projects[i]);
          }
        }
      }


    })
    .catch(err => {
      console.log(err);
    });
}
  
}

</script>


<style scoped>
.nav-link a{
  color: var(--primary) !important;
}

</style>
