<template>
<div>
  <div class = "container">

      <div class="row ml-0 mr-0">
          <div class = "col-lg-6 mt-10 proxima order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">Branding & Strategy</h1>
              <p class="custom-body mt-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <img class="width-full-auto-height" src="../assets/images/design/Design.png" alt="Design Page Banner"/>
          </div>
      </div>

  </div>

    <div class ="row bg-primary-op mt-10 text-center ml-0 mr-0">
      <div class="col-lg-12">
        <h5 class="custom-accent-header proxima pt-9 pb-2">LOREM IPSUM</h5>
        <h3 class="custom-sub-header aller-bd">Build Your Brand</h3>
        <div class ="design-wrapper">
          <p class="custom-body text-left mt-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          </p>
        </div>
      </div>
    </div>

<div class="bg-full-width">
  <div class = "container">
      <div class="row text-center ml-0 mr-0 pt-8 pb-8">
        <div class ="col-lg-3">
            <div class ="design-small-wrapper">                  
              <!-- <img src="../assets/images/220x165.png" alt="Placeholder Image"/> -->
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Custom Logo</h5>
              <p class="text-left custom-body proxima">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.
              </p>
            </div>
        </div>
        <div class ="col-lg-3">
            <div class ="design-small-wrapper">     
              <!-- <img src="../assets/images/220x165.png" alt="Placeholder Image"/> -->
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Color Palette</h5>
              <p class="text-left custom-body proxima">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.
              </p>
            </div>
        </div>
        <div class ="col-lg-3">
            <div class ="design-small-wrapper"> 
              <!-- <img src="../assets/images/220x165.png" alt="Placeholder Image"/> -->
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Font</h5>
              <p class="text-left custom-body proxima">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.
              </p>
            </div>
        </div>
        <div class ="col-lg-3">
            <div class ="design-small-wrapper"> 
              <!-- <img src="../assets/images/220x165.png" alt="Placeholder Image"/> -->
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Style Guide</h5>
              <p class="text-left custom-body proxima">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.
              </p>
            </div>
        </div>
      </div>

    </div>
</div>

<div class ="row ml-0 mr-0">
  <div class="col-lg-6 bg-primary text-right">
      <!-- <img class="pt-6 pb-6 pr-8" src="../assets/images/600x400.png" alt="Placeholder Image"/> -->
  </div>
  <div class="col-lg-6 pt-9 pl-10">
    <h5 class="custom-accent-header proxima">LOREM IPSUM</h5>
    <h3 class="custom-sub-header aller-bd pt-3 pb-3">Social Media</h3>
    <div class ="case-study-wrapper">
      <p class="text-left custom-body proxima">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
      </p>
    </div>
  </div>
</div>


<div class ="row ml-0 mr-0">
  <div class="col-lg-6 pt-9 pl-20">
    <h5 class="custom-accent-header proxima">LOREM IPSUM</h5>
    <h3 class="custom-sub-header aller-bd pt-3 pb-3">Print & Packaging</h3>
    <div class ="case-study-wrapper">
      <p class="text-left custom-body proxima">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
      </p>
    </div>
  </div>
  <div class="col-lg-6 bg-secondary text-left">
      <!-- <img class="pt-6 pb-6 pl-8" src="../assets/images/600x400.png" alt="Placeholder Image"/> -->
  </div>
</div>

  <div class = "container">

      <div class="row ml-0 mr-0 mt-10 mb-5 text-center">
          <div class = "col-lg-6">
            <!-- <img src="../assets/images/630x500.png" alt="Placeholder Image" /> -->
          </div>
          <div class = "col-lg-6 mt-10 proxima">
            <div class="contact-wrapper">
              <h5 class="custom-accent-header proxima">LET'S GET TO WORK</h5>
              <h1 class="aller-bd mt-4 mb-4">Get In Touch</h1>
              <button class="btn btn-lg btn-secondary">Set Up a Design Meeting</button>
            </div>
          </div>
      </div>

  </div>

</div>
</template>

<script>




export default {
  name: 'Projects',
  components: {},
  
}




</script>

<style>


</style>
