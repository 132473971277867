<template>
<div>
  <div class = "container">

      <div class="row ml-0 mr-0">
          <div class = "col-lg-6 mt-10 proxima order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">Design & Development</h1>
              <p class="custom-body light-font-weight mt-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <img class="width-full-auto-height" src="../assets/images/development/Development.png" alt="Development Page Banner"/>
          </div>
      </div>

  </div>

    <div class ="row bg-primary mt-15 mr-0 ml-0 pr-0 pl-0">
        <div class ="col-lg-12 text-center mt-5 pt-5">
          <div class="development-wrapper">
              <h5 class="white-accent-header proxima">LOREM IPSUM</h5>
              <h3 class="white-sub-header aller-bd pt-3 pb-4">Our Specialties</h3>
              <p class="white-body text-left">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
              </p>
          </div>
       </div>
    </div>

    <div class ="row bg-primary mr-0 ml-0 pt-8">
      <div class="col-lg-3"></div>

      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border custom-radius-top-left">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">Custom Websites</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>
      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border rounded-0">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">Web Applications</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>
      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border custom-radius-top-right">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">Mobile Applications</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>

      <div class="col-lg-3"></div>
    </div>

    <div class ="row bg-primary mr-0 ml-0">
      <div class="col-lg-3"></div>

      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border custom-radius-bottom-left">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">WordPress Plugins</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>
      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border rounded-0">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">Lorem Ipsum</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>
      <div class="col-lg-2 p-0 text-left">
      <b-card class="custom-border custom-border custom-radius-bottom-right">
        <b-row>
          <b-col md="3">
              <!-- <img src ="../assets/images/110x85.png" alt="Placeholder Image"/> -->
          </b-col>
          <b-col md="9">
            <b-card-body>
              <h3 class="custom-tiny-header aller-bd pt-3 pl-2">Lorem Ipsum</h3>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      </div>

      <div class="col-lg-3"></div>
    </div>

    <div class ="row bg-primary mr-0 ml-0 pb-20 triangular-clip-bottom"></div>
    <div class ="primary-op-bg-cover"></div>

<div class ="bg-full-width">
  <div class = "container">
    <div class ="row">
      <div class ="col-lg-12 text-center mt-4 pt-4">
        <h5 class="custom-accent-header proxima pt-2 pb-2">TOOLS OF THE TRADE</h5>
        <h3 class="custom-sub-header aller-bd pt-1 pb-1">What We Use</h3>
        <div class ="development-tools-wrapper">
          <p class="custom-body light-font-weight text-left mt-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          </p>
        </div>
      </div>
    </div>


    <div class="row mt-6 pb-6 ml-0 mr-0 pr-0 pl-0">
        <div class="col-lg-3">
          <div class="tool-img-wrapper">
              <img src ="../assets/images/tools/vue.svg" alt="Vue Logo"/>
          </div>
        </div>
        <div class="col-lg-3">
            <div class="tool-img-wrapper">
              <img src ="../assets/images/tools/laravel.svg" alt="Laravel Logo"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="tool-img-wrapper">
             <img src ="../assets/images/tools/tailwindcss.svg" alt="Tailwind Logo"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="tool-img-wrapper">
            <img class="tool-img-wrapper" src ="../assets/images/tools/wordpress.png" alt="Wordpress Logo"/>
          </div>
        </div>
    </div> 

  </div>
</div>

    <div class ="row ml-0 mr-0 pr-0 pl-0">
      <div class ="col-lg-12 text-center mt-4 pt-4">
        <h5 class="custom-accent-header proxima pt-6 pb-2">THE STEPS</h5>
        <h3 class="custom-sub-header aller-bd pt-1 pb-1">Our Process</h3>
        <div class ="development-tools-wrapper">
          <p class="custom-body light-font-weight text-left mt-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          </p>
        </div>
      </div>
    </div>

  <div class = "container">
    <div class="row mt-10 mb-12">
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <h5 class="custom-small-header aller-bd pt-3 pb-3">Planning & Budgeting</h5>
            <p class="custom-body proxima light-font-weight">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </p>
        </div>
      </div>
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <!-- <img src ="../assets/images/365x235.png" alt="Placeholder Image"/> -->
        </div>
      </div> 
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <ol>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
          </ol>
        </div>
      </div>         
    </div>
    <div class="row mt-10 mb-12">
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <ol>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
          </ol>
        </div>
      </div>
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <!-- <img src ="../assets/images/365x235.png" alt="Placeholder Image"/> -->
        </div>
      </div> 
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <h5 class="custom-small-header aller-bd pt-3 pb-3">Design Exercise</h5>
            <p class="custom-body proxima light-font-weight">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </p>
        </div>
      </div>         
    </div>
    <div class="row mt-10 mb-12">
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <h5 class="custom-small-header aller-bd pt-3 pb-3">Development</h5>
            <p class="custom-body proxima light-font-weight">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </p>
        </div>
      </div>
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <!-- <img src ="../assets/images/365x235.png" alt="Placeholder Image"/> -->
        </div>
      </div> 
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <ol>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
          </ol>
        </div>
      </div>         
    </div>
    <div class="row mt-10 mb-12">
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <ol>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
          </ol>
        </div>
      </div>
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <!-- <img src ="../assets/images/365x235.png" alt="Placeholder Image"/> -->
        </div>
      </div> 
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <h5 class="custom-small-header aller-bd pt-3 pb-3">Testing</h5>
            <p class="custom-body proxima light-font-weight">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </p>
        </div>
      </div>         
    </div>
    <div class="row mt-10 mb-12">
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <h5 class="custom-small-header aller-bd pt-3 pb-3">Approval & Launch</h5>
            <p class="custom-body proxima light-font-weight">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </p>
        </div>
      </div>
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <!-- <img src ="../assets/images/365x235.png" alt="Placeholder Image"/> -->
        </div>
      </div> 
      <div class = "col-lg-4 text-left">
        <div class ="project-description-wrapper">
          <ol>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
            <li class="custom-small-header aller-bd pt-4 pb-3"></li>
          </ol>
        </div>
      </div>         
    </div>

  </div>


    <div class ="row bg-primary mr-0 ml-0 pr-0 pl-0">
      <div class ="col-lg-12 text-center mt-8 mb-8">
              <h2 class="white-sub-header aller-bd mb-5">Interested in Working Together?</h2>
              <b-button variant="secondary" class="btn btn-lg mr-4 custom-btn">Estimate Your Project Cost</b-button>                    
              <b-button variant="outline-light" class="btn btn-lg ml-4 custom-btn">Schedule a Meeting</b-button>
      </div>
    </div>


</div>
</template>

<script>




export default {
  name: 'Projects',
  components: {},
  
}




</script>

<style scoped>
/deep/ .nav-link  {
  color: var(--white);
  margin: 0px 20px 0px 20px;
  padding: 5px 25px 5px 25px;
}

/deep/ .nav-link.active {
  border: 2px solid var(--white);
}

</style>
