import axios from 'axios';

axios.defaults.baseURL = 'https://db.bizzynate.com/api';

export default class ContactService {
    submitContact(data) {

    return axios({
        method: 'post', //you can set what request you want to be
        url: 'https://db.bizzynate.com/api/contact',
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        })
    }

}
