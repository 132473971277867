<template>
  <div>

  <div>
    <img class="homeHeader" src ="../assets/images/home/HomeHeader.png" />
  </div>
<div class="container">
    <div class = "pt-10 pt-lg-0 pt-md-0 pt-sm-5 pb-10 pb-lg-7 mt-xl-12 mt-lg-8 mt-md-12 mt-sm-0 hide-mobile">
        <div class = "row">
              <div class ="col-xxl-5 col-xl-5 col-lg-8 mt-2 ml-5">
                <h5 class="custom-accent-header proxima">Hi, we're BizzyNate Creative!</h5>
                <h3 class="custom-big-header aller-bd mt-3 mb-5">We Help You<br> Stand Out</h3>
                <div class = "row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-10">
                      <p class="custom-body proxima light-font-weight mb-0 thirty_five_wrapper">
                        We are a Graphic Design and Web Development firm based in Orlando, Florida. 
                        Founded in 2006, we’re a leader in brand development and web presence specializations for small- to medium-sized businesses, nonprofits, political campaigns, and social causes.
                      </p>
                        <b-button href="/contact" class="btn btn-lg btn-secondary custom-btn mt-5 aller-bd">Schedule a Meeting</b-button>
                        <b-button href="/about" variant="outline-secondary" class="btn btn-lg custom-btn mt-5 ml-5 aller-bd">Learn More</b-button>
                    </div>
                    <div class="col-lg-2"></div>
                </div>
              </div>

              <div class ="col-lg-6">
                <!-- <v-lottie-player
                  name="scooterAnim"
                  loop
                  path="https://assets9.lottiefiles.com/packages/lf20_gb5bmwlm.json"
                  background="transparent"
                  speed="1"
                  style="width: 100%; height: auto;"
                /> -->
              </div> 
        </div>
    </div>

    <div class = "show-mobile">
        <div class = "row px-3 pt-15 mb-8">
              <div class ="col-sm-12">
                <h5 class="custom-accent-header proxima text-center">Hi, we're BizzyNate Creative!</h5>
                <h3 class="custom-big-header aller-bd mt-3 mb-5 text-center">We Help You<br> Stand Out</h3>
                      <p class="custom-body proxima light-font-weight">
                        We are a Graphic Design and Web Development firm based in Orlando, Florida. 
                        Founded in 2006, we’re a leader in brand development and web presence specializations for small- to medium-sized businesses, nonprofits, political campaigns, and social causes.
                      </p>
              </div>
        </div>
    </div>

    <div id="servicesContainer" class = "row mr-0 ml-0 pr-0 pl-0 pt-xxl-15 pt-xl-0 pt-lg-0 pt-md-0 mt-xxl-8 mt-xl-15 mt-md-5 mt-sm-0 pt-md-0 pt-sm-0 mt-sm-0 pt-20 mt-12">
          <div class ="col-lg-12 text-center">
            <h5 class="custom-accent-header proxima">SERVICES</h5>
            <h3 class="custom-sub-header aller-bd pt-3 pb-xxl-5 pb-xl-5 pb-lg-5 pb-md-5 pb-sm-0 pb-xs-0">Everything You Need</h3>
          </div>
    </div>
    <div id="services"></div> 
    <div class ="row pb-lg-0 lg-pb-8 md-pb-8 sm-pb-0">

          <div class ="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 px-md-0 text-center fit-content">
            <div class="p-xxl-5 p-xl-5 p-lg-2 p-md-0 p-sm-0 serviceCard">
              <div class ="description-wrapper">
                <img src="../assets/images/home/Design.svg" alt="Design Icon"/>
                <h5 class="custom-small-header aller-bd pt-0 pt-lg-0 pb-3 text-center">Design</h5>
                <p class="text-left custom-body proxima light-font-weight">
                    We help brands establish their identity and translate it across all of their systems. 
                    Our digital products are meticulously designed to not only be aesthetically pleasing, but also to work across all devices and platforms. 
                    We focus on interactions that help guide users through a journey, increasing engagement and creating long lasting customers.
                </p>
                <b-button style="display: none;" href="/design" variant="primary" class="btn btn-lg custom-btn" role="button">Learn More</b-button>
              </div>
            </div>
          </div>

          <div class ="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 px-md-0 text-center fit-content">
            <div class="p-xxl-5 p-xl-5 p-lg-2 p-md-0 p-sm-0 serviceCard">
              <div class ="description-wrapper">
                <img class="pt-xxl-0 pt-xl-0 pt-lg-0 pt-5" src="../assets/images/home/Development.svg" alt="Development Icon"/>
                <h5 class="custom-small-header aller-bd pt-0 pt-lg-0 pb-3 text-center">Development</h5>
                <p class="text-left custom-body proxima light-font-weight">
                    We analyze the internal and industry needs of each individual client in order to build a product that is tailored specifically to them. 
                    We design ADA compliant websites and work with industry-leading platforms, which allows us to effectively create and extend the functionality 
                    of your products as your business continues to grow and evolve.
                </p>
                <b-button style="display: none;" href="/development" variant="primary" class="btn btn-lg custom-btn">Learn More</b-button>
              </div>
            </div>
          </div>
  
          <div class ="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 px-md-0 text-center fit-content">
            <div class="p-xxl-5 p-xl-5 p-lg-2 p-md-0 p-sm-0 serviceCard">
                <div class ="description-wrapper">
                  <img class="pt-xxl-0 pt-xl-0 pt-lg-0 pt-5" src="../assets/images/home/Management.svg" alt="Management Icon"/>
                  <h5 class="custom-small-header aller-bd pt-0 pt-lg-0 pb-3 text-center">Management</h5>
                  <p class="text-left custom-body proxima light-font-weight">
                      We provide a secure, trustworthy option for hosting and maintaining your digital products. 
                      We provide website optimizations, network monitoring, system backup and restoration, and monthly maintenance plans. 
                      Through our hosting options, we provide you with your own dedicated server and free Secure Sockets Layer (SSL) certificates to improve your site security.
                  </p>
                  <b-button style="display: none;" href="/maitenance" variant="primary" class="btn btn-lg custom-btn">Learn More</b-button>
                </div>
            </div>
          </div>
          
  
    </div>
</div>

      <div class = "row pt-0 mb-7 mt-7 sm-pb-0">
            <div class ="col-lg-6 col-md-12 pl-xxl-6 pl-xl-6 pl-lg-6 pl-md-0 pl-sm-0 pt-md-5 mt-5 mt-lg-4 mt-md-5 mb-0 mb-sm-5">
                <img class="case-study-img" src="../assets/images/home/26HealthiMac.png" alt="26Health Website Mockup Image"/>
            </div>
            <div class ="col-lg-6 col-md-12 px-5 mt-xxl-8 mt-lg-5 mt-md-0 mt-sm-0 pt-md-0">
                <h5 class="custom-accent-header proxima pt-xxl-0 pt-xl-0 pt-4 responsive-align-left">FEATURED PROJECT</h5>
                <h3 class="custom-sub-header aller-bd pt-2 pb-2">26Health.org</h3>
                <div class ="case-study-wrapper">
                  <p class="text-left custom-body proxima light-font-weight">
                    26Health is a non-profit, 501c3 corporation that offers comprehensive health care services to the LGBTQ+ community and allies in Central Florida. 
                    Their services cater to all ages and they accept insurance plans as well as provide accessibility plans for those with little or no income. 
                    Every step of the way they embody their mantra “care for every letter” by providing options for all individuals, regardless of their circumstances. 
                  </p>
                  <p class="text-left custom-body proxima light-font-weight">
                    We leveraged WordPress and developed a couple of add-ons for the Gutenberg block editor that integrate with the theme to add some enhanced design features for their headers and corporate icon set. 
                    Gutenberg blocks all leverage React, and much of our work was done inside that framework. We also designed and developed a custom plugin to handle appointment requests. 
                    This process is a fully customized user flow built using Vue JS that interfaces with the Gravity Forms API endpoints inside of Wordpress.
                  </p>
                  <div class="responsive-align-left full-width">
                    <b-button href="https://26health.org/" target="_blank" class="btn btn-lg btn-secondary custom-btn mt-4 aller-bd">View Website</b-button>
                  </div>
                </div>
            </div>  
      </div>



      <div style="display:none" class = "row pt-xxl-8 pt-xl-8 pt-lg-8 pt-md-0 pt-sm-0 pb-lg-20 pb-sm-0 variable-bg">
            <div class ="col-lg-12 text-center">
                <h5 class="custom-accent-header proxima">CLIENTS</h5>
                <h3 class="custom-sub-header aller-bd pt-2 pb-6">Happy Customers</h3>
                   <carousel :per-page="5" :mouse-drag="true" :autoplay="true" :loop="true" autoplayDirection="forward" :autoplayTimeout="parseInt(5000)" :paginationEnabled="false">
                      <template v-for="project in projects">
                          <slide v-if="project.logo_url && project.carouselIndex == 0" :key="project.id">
                                    <img class="logoStyle" :src="require(`../assets/${project.logo_url}`)" :alt="`${project.name} Logo`" />
                          </slide>
                      </template>
                    </carousel>
                  <carousel :per-page="5" :mouse-drag="true" :autoplay="true" :loop="true" autoplayDirection="backward" :autoplayTimeout="parseInt(5000)" :paginationEnabled="false">
                    <template v-for="project in projects">
                        <slide v-if="project.logo_url && project.carouselIndex == 1" :key="project.id">
                                  <img class="logoStyle" :src="require(`../assets/${project.logo_url}`)" :alt="`${project.name} Logo`" />
                        </slide>
                    </template>
                  </carousel>
            </div>  
      </div>

      <div style="display:none" class="hide-mobile">
        <img id="cloudHeader" src ="../assets/images/home/CloudDivider.svg" alt="Decorative Cloud Divider" />
      </div>

      <div style ="display:none;" class = "container">

          <div class="row ml-0 mr-0 mt-6 mb-5 text-left">
              <div class = "col-lg-6 mt-8 proxima">
                <div class="contact-wrapper">
                  <h5 class="custom-accent-header proxima">HAVE A PROJECT?</h5>
                  <h1 class="aller-bd mt-4 mb-4">Use Our MVP Estimator</h1>
                  <p class="custom-body light-font-weight mt-4">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                      At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                  </p>
                  <b-button variant="primary" class="btn btn-lg mr-2">Estimate Your Project Cost</b-button>                    
                  <b-button variant="outline-primary" class="btn btn-lg">Schedule a Meeting</b-button>
                </div>
              </div>
              <div class = "col-lg-6">
                <!-- <img src="../assets/images/700x600.png" alt="Placeholder Image" /> -->
              </div>
          </div>

      </div>

      

</div>

</template>

<script>

//import VueLottiePlayer from "vue-lottie-player";
import { Carousel, Slide } from 'vue-carousel';

import ProjectService from '../services/ProjectService.js';
const projectService = new ProjectService;

export default {
  name: 'Home',

  data() {
    return {
          projects: [],
        };
  },
  components: {Carousel, Slide},

  mounted() {
    projectService.getProjects()
    .then(res => {
      let projects = res.data;
      projects.map(project => project.carouselIndex = Math.floor(Math.random() * (1 + 1)));
      this.projects = projects;
    })
    .catch(err => {
      console.log(err);
    });
}

}

</script>

