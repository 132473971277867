<template>
  <div class="hide-X" >

  <div class="hide-mobile ">
    <img class="homeHeader" src ="../assets/images/404/404.png" />
  </div>

  <div class="show-mobile">
    <img class="homeHeader" src ="../assets/images/404/404Mobile.png" />
  </div>

     <div class = "not-found-btn-wrap pl-lg-10 pl-md-0 pl-xs-0 pl-sm-0 pt-10 pt-sm-5 ml-sm-0 pl-sm-0 pb-10">

        <div class = "row mt-4 hide-mobile">
                <b-button href="/" target="_blank" class="btn btn-lg btn-secondary custom-btn mt-5">Return Home</b-button>
                <b-button href="/contact" target="_blank" variant="outline-secondary" class="btn btn-lg custom-btn mt-5 ml-5">Report an Issue</b-button>
        </div>

        <div class = "row show-mobile mx-5 mt-8">

                <h1 class="custom-big-header aller-bd mb-5 text-center">404</h1>
                <h4 class="aller text-center">Oops. We couldn't find the page you are looking for.</h4>
                  <b-button href="/" target="_blank" class="btn btn-block btn-lg btn-secondary custom-btn mt-5">Return Home</b-button>
                  <b-button href="/contact" target="_blank" variant="outline-secondary" class="btn btn-block btn-lg custom-btn mt-5">Report an Issue</b-button>

        </div>
    </div>
                
  <div class="not-found-height"></div>
</div>

</template>

<script>

export default {
  name: 'Home',

  data() {
    return {
          projects: [],
        };
  },
  components: {},

  mounted() {

}

}

</script>

