<template>
  <div id="app">
    <NavBar/>

    <!-- Body Content -->
      <div class="hide-X">
          <router-view/>
      </div>
      
    <Footer/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
@import url('https://use.typekit.net/srx8bln.css');

@font-face {
  font-family: "Aller_Rg";
  src: local("Aller_Rg"),
   url(./assets/fonts/Aller_Rg.ttf) format("truetype");
}

@font-face {
  font-family: "Aller_Bd";
  src: local("Aller_Bd"),
   url(./assets/fonts/Aller_Bd.ttf) format("truetype");
}

</style>
