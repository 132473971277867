import Vue from 'vue'
import App from './App.vue'
import { BIconArrowRight, BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

import './assets/scss/style.scss'
import router from './router';
import VueLottiePlayer from "vue-lottie-player";
import VueCarousel from 'vue-carousel';
//import VueGtag from "vue-gtag";


//Add Google Analytics Tag Here
// Vue.use(VueGtag, {
//   config: { id: "UA-1234567-1" }
// }, router);
 
Vue.use(VueCarousel);
Vue.use(VueLottiePlayer);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('BIconArrowRight', BIconArrowRight)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
