<template>
  <div class="sticky-Nav" id="menuBar">
    <div class="container">
      <b-navbar id="menuContainer" class="py-lg-4 px-lg-5 mt-lg-5 w-sm-100 rounded " toggleable="lg" type="light" variant="light" sticky>

        <b-navbar-brand class="desktop-logo" data-toggle="nav-collapse" data-target="nav-collapse" to="/" >
            <img class="logo-styling" src="./../assets/images/BN-Resized.png"/>
        </b-navbar-brand>
    
        <b-navbar-brand data-toggle="nav-collapse" data-target="nav-collapse" to="/" >
          <b-navbar-toggle target="nav-collapse" to="/" >
            <img class="logo-styling" src="./../assets/images/BN-Resized.png"/>
          </b-navbar-toggle>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
                  <b-nav-item class="custom-nav-link responsive-align-right" to="/about" right>About</b-nav-item>
                  <!-- <b-nav-item class="custom-nav-link" href="/#services" right>Services</b-nav-item> -->
                  <b-nav-item class="custom-nav-link responsive-align-right" to="/projects" right>Projects</b-nav-item>
                  <!-- <b-nav-item class="custom-nav-link" href="/contact" right>Contact Us</b-nav-item> -->
                  <b-nav-item class="responsive-align-right">
                    <b-button variant="primary" class="nav-btn btn-block" to="/contact" right>
                      Contact Us
                    </b-button>
                  </b-nav-item>
                  <!-- <button class="btn btn-primary nav-btn" href="#" right>
                    MVP Estimator
                  </button> -->

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {

const menuBar = document.getElementById('menuBar');
const menuContainer = document.getElementById('menuContainer');

  window.addEventListener("scroll", () => {
    if (window.scrollY < 20) {
      menuBar.classList.remove('menuFull');
      menuBar.classList.add('menuFade');
      menuBar.classList.remove('shadow-sm');
      menuContainer.classList.add('shadow-sm');
    } else {
      menuBar.classList.add('menuFull');
      menuBar.classList.remove('menuFade');
      menuBar.classList.add('shadow-sm');
      menuContainer.classList.remove('shadow-sm');
    }
    });


    }
  }
</script>