import axios from 'axios';

axios.defaults.baseURL = 'https://db.bizzynate.com/api';

export default class ProjectService {
    getProjectData(id) {
        return axios.get(`/project/${id}`);
    }
    getProjects() {
        return axios.get(`/projects`);
    }

}
